import { Box } from '@chakra-ui/react'
import { RandomMarketplace } from 'components/templates'
import { ExploreMarket, HeroCarousel } from 'components/templates/home'
import { Container } from 'components/templates/layout'
import Head from 'next/head'
import { formatHtmlTitle } from 'utils/globals'

const Home = () => (
  <>
    <Head>
      <title key="title">{formatHtmlTitle('Home')}</title>
    </Head>
    <Box>
      <HeroCarousel />
      <Container position="relative" pb={[3, 5, 6, 10, '100px']} mt="20px" zIndex={10}>
        {/* <FeaturedItems /> */}
        <RandomMarketplace />
      </Container>
      {/* <BigTimeSale /> */}
      <ExploreMarket />
      {/* <GameSubmission /> */}
      {/* <NewsLetter /> */}
    </Box>
  </>
)

export default Home
